import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { fetchActivityStatusFeedback, fetchCampaignDetail } from '../../../network/campaignCurd';
import { CampaignLeadItem } from '../../../types/campaign-types';
import { useHistory } from 'react-router-dom';
import { reconstuctNameByLastName } from 'src/app/common/utils';

interface HookProps {
  id: string;
  // other params
}

type ActivityStatus = {
  key: string;
  value: string;
};

type ServicingCampaignContextProps = {
  activityStatusFeedback: ActivityStatus[];
  refresh: () => void;
};

export const ServicingCampaignContext = React.createContext<ServicingCampaignContextProps>({
  activityStatusFeedback: [],
  refresh: () => {},
});

export const useDetailPage = ({ id }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  const { data, error, loading, run, runAsync } = useRequest(() => fetchCampaignDetail(id, dispatch), {
    manual: false,
  });

  const activityStatusFeedback = useRequest(() => fetchActivityStatusFeedback(id), {
    manual: false,
  });

  const convertLeadData = (data: any, activityStatusFeedback: any): Partial<CampaignLeadItem>[] => {
    if (!data) return [];
    const leads: Partial<CampaignLeadItem>[] = [];
    data.leads.forEach((lead: any) => {
      const feedbackObject = activityStatusFeedback.data?.find((item: any) => item.key === lead.feedback);
      leads.push({
        ...lead,
        _id: lead._id,
        name: reconstuctNameByLastName(lead.prospectDetail.firstName, lead.prospectDetail.lastName),
        phoneNumber: lead.prospectDetail.phoneNumber,
        campaignName: data.name,
        leadSource: data.name,
        updatedAt: lead.updatedAt,
        lastViewedAt: lead.lastViewedAt,
        feedback: feedbackObject?.value ?? Translation('campaign.servicing.feedback.pendingStatus'),
      });
    });
    return leads;
  };

  const navToList = () => {
    history.goBack();
  };

  const refresh = () => {
    run();
  };

  return {
    loading,
    data: data,
    leadsData: convertLeadData(data, activityStatusFeedback),
    activityStatusFeedback: activityStatusFeedback.data,
    run,
    navToList,
    refresh,
  };
};
