// sales-lead
export enum SalesLeadStatusEnum {
  pendingContact = 'pendingContact',
  contactSuccess = 'contactSuccess', // contact
  met = 'met',
  quote = 'quote', // proposal
  proposal = 'proposal', // application submitted
  policy = 'policy', // policy issued
  dead = 'dead',
  autoDrop = 'AUTO_DROPPED',
  /**
   * ABOVE status can map to DB field:lead-coe-dev-encrypt.lead.followUp
   * BELOW status can NOT map to DB
   */
  issued = 'issued', // Not in-use, old-data status?
  pending = 'pending', // only for UI
  created = 'created', // only for UI
}

export interface FollowUp {
  status: string;
  createdAt?: string; // date string. e.g. '2024-04-18T14:14:25.991Z'
  expiredAt?: string; // date string.
  submissionDate?: string; // date string.
  reason?: string;
  reasons?: string[];
  icon?: 'dead' | 'completed' | 'active';
  isFirst?: boolean; // extra props for status history
  isActive?: boolean; // extra props for status history
  issuedPolicy?: Array<{ policyNumber: string }>; // extra props for status history
}

export enum LeadCategoryEnum {
  SALES_LEAD = 'SalesLead',
  RECRUIT_LEAD = 'RecruitLead',
}
