import React, { useEffect, useMemo, useState, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { cloneDeep, get, isEqual } from 'lodash';
import { useLang } from 'src/app/i18n';

import { PruToast } from 'src/app/common/components/pru-toast';
import * as H from 'history';
import {
  checkAllRsvpResponded,
  changeRsvpStatus,
  createNewCampaign,
  fetchCampaignItem,
  fetchCampaignTypeStructure,
  fetchParticipantList,
  removeParticipant,
  sendInvitation,
  submitCampaign,
  updateCampaign,
} from '../../../network/campaignCurd';
import { CampaignStructureItem, RsvpStatus } from '../../../types/campaign-types';
import { useParams } from 'react-router-dom';
import { AgencyCampaignTypeStatusEnum } from '../../../constants';
import moment from 'moment';
import { ApiErrorResponse } from 'src/app/common/network/apiErrorHandler';
import { ErrorFieldType, useAcmErrorHandler } from 'src/app/common/utils';
import { convertArrayToObject, getDisplayName } from 'src/app/common/utils/common-utils';
import {
  validationCompareChecking,
  validationLengthChecking,
  validationMandatoryDependsChecking,
  validationCurrencyChecking,
} from '../../../utils/validations';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { addBusinessDays } from 'date-fns';
import { checkAgentImportFail, renderfailImportList } from '../Details/application/components/ImportParticipantPopup';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { OperatorEnum } from '../../../types/common-types';
import { isEmptyArray } from 'formik';

interface HookProps {
  history: H.History;
  location: H.Location;
}

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    section: string;
    field: keyof any;
    value: any;
  };
};

type StepAccessAction = {
  type: 'STEP_ACCESS';
  payload: number;
};

type StepPopulateAction = {
  type: 'STEP_POPULATE';
  payload: number | number[];
};

type InitFieldAction = {
  type: 'INIT_FORM_DATA';
  payload: {
    value: any;
  };
};

type infoDataFormAction = ModifyFieldAction | InitFieldAction | StepAccessAction | StepPopulateAction;

const initialState: any = {
  stepAccessed: [],
  stepPopulated: [],
  count: 0,
};

const infoDataFormReducer = (state: any, action: infoDataFormAction): any => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          [action.payload.field]: action.payload.value,
        },
        count: state.count + 1,
      };
    case 'INIT_FORM_DATA':
      let formState = {
        ...state,
        ...action.payload.value,
        count: state.count + 1,
      };
      return formState;
    case 'STEP_ACCESS':
      return {
        ...state,
        stepAccessed: [...new Set(state.stepAccessed.concat(action.payload))],
      };
    case 'STEP_POPULATE':
      const stepPopulated = [...new Set(state.stepPopulated.concat(action.payload))];
      return {
        ...state,
        stepPopulated,
      };
  }
};

export enum TabsConfig {
  APPLICATION = 'application',
  APPROVAL = 'approval',
  POST_DOC = 'post-document',
  LEAD = 'lead',
}

export const useDetail = ({ history, location }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const locale = useLang();

  const { typeId } = useParams<{ typeId: string }>();
  const { id } = useParams<{ id: string }>();
  const { code } = useMemo(() => ({ code: id ? id : undefined }), [id]);

  // redux
  const dispatch = useDispatch();
  const [currentCampaign, setCurrentCampaign] = useState<any>({ campaignTypeId: typeId ? typeId : '' });
  const [campaignTypeStructureData, setCampaignTypeStructureData] = useState<CampaignStructureItem>();
  const [formState, formDispatch] = useReducer(infoDataFormReducer, initialState);
  const [previousFormState, setPreviousFormState] = useState<any>(initialState);
  const [participantSelected, setParticipantSelected] = useState<any[]>([]);
  const [validationArray, setValidationArray] = useState<any[]>([]);
  const currentAgentInfo = useMemo(() => {
    return globalStore.getAgent();
  }, []);
  const [submitDateShouldGreaterThan, setSubmitDateShouldGreaterThan] = useState<any>();
  const [campaignEndDateEqualToStartDate, setCampaignEndDateEqualToStartDate] = useState<any>();
  const [campaignEndDateGreaterThanTarget, setCampaignEndDateGreaterThanTarget] = useState<any>();
  const [RSVPMandatory, setRSVPMandatory] = useState<boolean>(false);
  const [participantNameRules, setParticipantNameRules] = useState<any>({});
  const [disableBulkSelect, setDisableBulkSelect] = useState<boolean>(true);
  const [removeSuccessDialogOpen, setRemoveSuccessDialogOpen] = useState<boolean>(false);
  const [inviteSuccessDialogOpen, setInviteSuccessDialogOpen] = useState<boolean>(false);
  const [approvalData, setApprovalData] = useState<any>([]);
  const [toggleLeadUploadWizard, setToggleLeadUploadWizard] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<TabsConfig>(TabsConfig.APPLICATION);

  const [canEventDocSubmit, setCanEventDocSubmit] = useState(false);
  const [canSubmitCampaign, setCanSubmmitCampaign] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [disableUserActions, setDisableUserActions] = useState<boolean>(false);

  const updateRSVPStatus = async (rsvpStatus: string) => {
    if (currentCampaign.rsvpStatus === rsvpStatus) {
      return;
    }
    if (rsvpStatus === RsvpStatus.Accepted) {
      let data = { campaignId: currentCampaign.campaignObjId, status: RsvpStatus.Accepted };
      changeRsvpStatus(data, dispatch).then(() => {
        setCurrentCampaign((prev: any) => ({ ...prev, rsvpStatus }));
        setShowReminder(false);
      });
    } else if (rsvpStatus === RsvpStatus.Rejected) {
      let data = { campaignId: currentCampaign.campaignObjId, status: RsvpStatus.Rejected };
      changeRsvpStatus(data, dispatch).then(() => {
        setCurrentCampaign((prev: any) => ({ ...prev, rsvpStatus }));
        setShowReminder(false);
      });
    }
  };

  const loadCampaignTypeStructure = () => {
    if (currentCampaign.campaignTypeStructureData) {
      const result = currentCampaign.campaignTypeStructureData;
      setCampaignTypeStructureData(result);
      handleValidationArray(result);
      setApprovalData(result.approvalSetting);
      return;
    }
    if (currentCampaign.campaignTypeId) {
      fetchCampaignTypeStructure(currentCampaign.campaignTypeId, dispatch)
        .then((result) => {
          setCampaignTypeStructureData(result);
          handleValidationArray(result);
          setApprovalData(result.approvalSetting);
        })
        .catch((err: ApiErrorResponse) => {
          // history.push(NOT_FOUND_ERROR_PAGE_PATH);
        });
    }
  };

  const validationHandler = useAcmErrorHandler(formState, validationArray);

  const handleValidationArray = (dataStructure: any) => {
    const mandatoryArray: any[] = [];
    dataStructure.sections
      .filter((item: any) => !['participant'].includes(item.key))
      .map((sectionItem: any) => {
        sectionItem.sectionFields.map((fieldItem: any) => {
          let ruleObject = convertArrayToObject(fieldItem.rules, 'title');
          if (ruleObject.Mandatory && ruleObject.Mandatory.key === 'mandatory' && ruleObject.Mandatory.value) {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
            });
          }
          if (ruleObject.Mandatory && ruleObject.Mandatory.key === 'mandatoryDepends') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
              condition: (updatedFormState: any) => {
                return validationMandatoryDependsChecking(
                  sectionItem.key,
                  fieldItem.key,
                  updatedFormState,
                  ruleObject.Mandatory,
                );
              },
            });
          }

          if (ruleObject.Validation) {
            switch (ruleObject.Validation.key) {
              case 'length':
                mandatoryArray.push({
                  section: sectionItem.key,
                  name: fieldItem.key,
                  fieldType: ErrorFieldType.IMMEDIATE,
                  condition: (updatedFormState: any) => {
                    return validationLengthChecking(
                      sectionItem.key,
                      fieldItem.key,
                      updatedFormState,
                      ruleObject.Validation,
                    );
                  },
                });
                break;
              case 'compare':
                if (ruleObject.Validation.dependsOn && ruleObject.Validation.dependsOn.indexOf('submitDate') >= 0) {
                  setSubmitDateShouldGreaterThan({
                    section: sectionItem.key,
                    field: fieldItem.key,
                    value: ruleObject.Validation.value,
                  });
                } else if (
                  fieldItem.key === 'campaignEndDate' &&
                  ruleObject.Validation.dependsOn &&
                  ruleObject.Validation.dependsOn.indexOf('campaignStartDate') >= 0
                ) {
                  if (ruleObject.Validation.operator === 'eq' && ruleObject.Validation.value == 0) {
                    setCampaignEndDateEqualToStartDate({
                      setEqual: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                    });
                  }
                  if (ruleObject.Validation.operator === 'gte' && ruleObject.Validation.value > 0) {
                    setCampaignEndDateGreaterThanTarget({
                      setGreateThan: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                      targetDays: Number(ruleObject.Validation.value),
                    });
                  }
                } else {
                  mandatoryArray.push({
                    section: sectionItem.key,
                    name: fieldItem.key,
                    fieldType: ErrorFieldType.IMMEDIATE,
                    condition: (updatedFormState: any) => {
                      return validationCompareChecking(
                        sectionItem.key,
                        fieldItem.key,
                        updatedFormState,
                        ruleObject.Validation,
                      );
                    },
                  });
                  break;
                }
                break;
              default:
                break;
            }
          }

          if (fieldItem.type === 'currency') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.IMMEDIATE,
              condition: (updatedFormState: any) => {
                return validationCurrencyChecking(sectionItem.key, fieldItem.key, updatedFormState);
              },
            });
          }
        });
      });

    const participantSection =
      dataStructure.sections.filter((item: any) => item.key == 'participant').length > 0
        ? dataStructure.sections.filter((item: any) => item.key == 'participant')[0]
        : undefined;

    if (participantSection) {
      const participantSectionField = convertArrayToObject(participantSection.sectionFields, 'key');
      if (participantSectionField['tnc']) {
        let ruleObject = convertArrayToObject(participantSectionField['tnc'].rules, 'title');
        if (ruleObject.Mandatory && ruleObject.Mandatory.value == true) {
          mandatoryArray.push({
            section: 'participant',
            name: 'tnc',
            fieldType: ErrorFieldType.MANDATORY,
          });
        }
      }
      if (participantSectionField['rsvpResponse']) {
        let ruleObject = convertArrayToObject(participantSectionField['rsvpResponse'].rules, 'title');
        if (ruleObject.Mandatory && ruleObject.Mandatory.value == true) {
          setRSVPMandatory(true);
        }
      }
      if (participantSectionField['participantName']) {
        let compareRules = participantSectionField['participantName'].rules.filter(
          (item: any) => item.key == 'compare',
        );
        let compareRulesObject = convertArrayToObject(compareRules, 'operator');
        let setRules = {
          ...(compareRulesObject.gte && compareRulesObject.gte.value && { gte: compareRulesObject.gte.value }),
          ...(compareRulesObject.lte && compareRulesObject.lte.value && { lte: compareRulesObject.lte.value }),
        };
        setParticipantNameRules(setRules);
      }
    }
    setValidationArray(mandatoryArray);
  };

  const checkStepHaveError = (): Record<string, boolean> => {
    let isInfoValid = true;
    let isParticipantValid = true;
    let isAttachmentValid = true;
    if (campaignTypeStructureData) {
      const infoSection: string[] = [];
      const participantSection = [];
      const attachmentSection = [];
      for (let index = 0; index < campaignTypeStructureData.sections.length; index++) {
        const section = campaignTypeStructureData.sections[index];
        if ('postDocuments' !== section.key) {
          if (section.key === 'participant') {
            participantSection.push(section.key);
          } else if (section.key === 'attachments') {
            attachmentSection.push(section.key);
          } else {
            infoSection.push(section.key);
          }
        }
      }
      const { currentErrorState, formState } = validationHandler.onSubmitErrorValidator();
      // need to check both mandatory and immedia error
      const mandatoryErrorState = { ...currentErrorState.mandatory };
      const immediateErrorState = { ...currentErrorState.immediate };
      for (const key in mandatoryErrorState) {
        if (Object.prototype.hasOwnProperty.call(mandatoryErrorState, key)) {
          const status = mandatoryErrorState[key];
          const sectionKey = key.toString().split('_')[0];
          if (infoSection.includes(sectionKey) && status && isInfoValid) {
            isInfoValid = false;
          }
          if (participantSection.includes(sectionKey) && status) {
            isParticipantValid = false;
          }
          if (attachmentSection.includes(sectionKey) && status) {
            isAttachmentValid = false;
          }
        }
      }

      for (const key in immediateErrorState) {
        if (Object.prototype.hasOwnProperty.call(immediateErrorState, key)) {
          const status = immediateErrorState[key];
          const sectionKey = key.toString().split('_')[0];
          if (infoSection.includes(sectionKey) && status && isInfoValid) {
            isInfoValid = false;
          }
        }
      }
    }
    return { isInfoValid, isParticipantValid, isAttachmentValid };
  };

  const disabledEdit = useMemo(() => {
    const disableForEventDocument =
      currentCampaign.campaignStatus === AgencyCampaignTypeStatusEnum.PENDING ||
      currentCampaign.campaignStatus === AgencyCampaignTypeStatusEnum.APPROVED ||
      currentCampaign.campaignStatus === AgencyCampaignTypeStatusEnum.ACTIVE ||
      currentCampaign.campaignStatus === AgencyCampaignTypeStatusEnum.COMPLETED;
    return disableForEventDocument;
  }, [currentCampaign.campaignStatus]);

  const disabled = useMemo(() => {
    // disabled edit for PCAAEB-28275
    if (currentAgentInfo?.['agentCode'] && formState?.applicant?.applicantAgentCode) {
      return currentAgentInfo['agentCode'] !== formState.applicant.applicantAgentCode;
    } else {
      return false;
    }
  }, [formState, currentAgentInfo]);

  const noPostDoc = useMemo(() => {
    if (campaignTypeStructureData) {
      return (
        get(campaignTypeStructureData.sections.filter((item) => item.key === 'postDocuments')[0], 'sectionFields', [])
          .length < 1
      );
    } else {
      return true;
    }
  }, [campaignTypeStructureData]);

  const reloadData = async () => {
    if (currentCampaign.campaignId) {
      await fetchCampaignItem(currentCampaign.campaignId, dispatch)
        .then((result) => {
          formDispatch({ type: 'INIT_FORM_DATA', payload: { value: result.agencyCampaign } });
          setPreviousFormState(cloneDeep(result.agencyCampaign));
          const _currentCampaign = {
            ...currentCampaign,
            campaignTypeId: result.agencyCampaign.typeId,
            campaignStatus: result.status,
            approvalHistoryList: result.agencyCampaign.approvalHistoryList,
            remarksList: result.agencyCampaign.remark,
            campaignObjId: result._id,
            previousApprovalData: result.agencyCampaign.previousApprovalHistories,
            campaignTypeStructureData: result.agencyCampaign._campaignType,
            campaignOwner: result.createdBy,
            rsvpStatus: result.agencyCampaign?.rsvpStatus ? result.agencyCampaign?.rsvpStatus : null,
            isRequiredRsvp: !!result.agencyCampaign?.isRequiredRsvp,
            applicantAgentCode: result?.agencyCampaign?.applicant?.applicantAgentCode,
            freyaMigratedInd: result?.freyaMigratedInd ? result.freyaMigratedInd : null,
          };
          if (result.agencyCampaign.submitDate) {
            const { submitDate, cancelDate, canceledBy, cancelReason } = result.agencyCampaign;
            _currentCampaign.submitData = {
              submitDate: submitDate,
              submitBy: result.agencyCampaign.applicant.applicantName,
              cancelDate: cancelDate,
              canceledBy: canceledBy,
              cancelReason,
            };
          }
          _currentCampaign.startDate = moment(result.startDate).toDate();
          _currentCampaign.endDate = moment(result.endDate).toDate();
          setCurrentCampaign(_currentCampaign);
          if (_currentCampaign?.freyaMigratedInd) {
            setDisableUserActions(_currentCampaign.freyaMigratedInd);
          } else {
            setDisableUserActions(false);
          }
        })
        .catch((err: ApiErrorResponse) => {
          // history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    setCurrentCampaign({ ...currentCampaign, campaignId: code });
  }, [code]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [currentCampaign.campaignId]);

  useEffect(() => {
    loadCampaignTypeStructure();
    // eslint-disable-next-line
  }, [currentCampaign.campaignTypeId]);

  useEffect(() => {
    autoPopulatedField();
    // eslint-disable-next-line
  }, [currentAgentInfo, campaignTypeStructureData]);

  const autoPopulatedField = () => {
    if (!campaignTypeStructureData || !currentAgentInfo) {
      return;
    }
    let previousData = { ...previousFormState };
    campaignTypeStructureData.sections
      .filter((item: any) => item.key !== 'participant')
      .map((sectionItem: any) => {
        sectionItem.sectionFields.map((fieldsItem: any) => {
          if (!(formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key])) {
            switch (fieldsItem.key) {
              case 'applicantName':
                const displayName = getDisplayName(currentAgentInfo, locale);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: displayName },
                });
                previousData[sectionItem.key] = { ...previousData[sectionItem.key], [fieldsItem.key]: displayName };
                previousData.count++;
                break;
              case 'applicantAgentCode':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    section: sectionItem.key,
                    field: `${fieldsItem.key}`,
                    value: currentAgentInfo['agentCode'],
                  },
                });
                previousData[sectionItem.key] = {
                  ...previousData[sectionItem.key],
                  [fieldsItem.key]: currentAgentInfo['agentCode'],
                };
                previousData.count++;
                break;
              case 'applicantPhoneNumber':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    section: sectionItem.key,
                    field: `${fieldsItem.key}`,
                    value: currentAgentInfo['phone']['mobile'],
                  },
                });
                previousData[sectionItem.key] = {
                  ...previousData[sectionItem.key],
                  [fieldsItem.key]: currentAgentInfo['phone']['mobile'],
                };
                previousData.count++;
                break;
              case 'applicantEmail':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: currentAgentInfo['email'] },
                });
                previousData[sectionItem.key] = {
                  ...previousData[sectionItem.key],
                  [fieldsItem.key]: currentAgentInfo['email'],
                };
                previousData.count++;
                break;
              case 'numsOfPolicy':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: '0' },
                });
                break;
              case 'totalApe':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: '$0' },
                });
                previousData[sectionItem.key] = { ...previousData[sectionItem.key], [fieldsItem.key]: '$0' };
                previousData.count++;
                break;
              default:
                break;
            }
          }
        });
      });
    setPreviousFormState(previousData);
  };

  const backToList = () => {
    if (!disabledEdit && formStateUpdated && !disabled) {
      PruToast({
        message: Translation('agencyCampaign.common.saveToast'),
      });
      return;
    }
    history.goBack();
  };

  const isCampaignInfoUpdated = useMemo(() => {
    if (
      !isEqual(previousFormState.campaign, formState.campaign) ||
      !isEqual(previousFormState.applicant, formState.applicant)
    ) {
      return true;
    } else {
      return false;
    }
  }, [formState.applicant, formState.campaign, previousFormState.applicant, previousFormState.campaign]);

  const isCampaignFilesUpdated = useMemo(() => {
    const res: { [section: string]: boolean } = {};
    Object.entries(formState.attachments ?? {}).forEach((item) => {
      const sectionKey = item[0];
      const sources = item[1];
      const previousSources = previousFormState.attachments[sectionKey];
      res[sectionKey] = !isEqual({ sources }, { sources: previousSources });
    });
    return res;
  }, [formState.attachments, previousFormState.attachments]);

  // AttachmentUpdated
  const isAttachmentUpdated = useMemo(() => {
    const { attachment } = formState.attachments ?? {};
    const { attachment: oldAttachment } = previousFormState.attachments ?? {};
    if (!isEqual({ attachment: oldAttachment }, { attachment })) {
      return true;
    } else {
      return false;
    }
  }, [formState.attachments, previousFormState.attachments]);
  // ProofUpdated
  const isProofUpdated = useMemo(() => {
    const { proofOfLocation } = formState.attachments ?? {};
    const { proofOfLocation: oldProofOfLocation } = previousFormState.attachments ?? {};
    if (!isEqual({ proofOfLocation: oldProofOfLocation }, { proofOfLocation })) {
      return true;
    } else {
      return false;
    }
  }, [formState.attachments, previousFormState.attachments]);
  // post even doc
  // ProofUpdated
  const isPostEventDocUpdated = useMemo(() => {
    return false;
  }, []);

  // FormStateUpdated
  const formStateUpdated = useMemo(() => {
    return (
      isCampaignInfoUpdated ||
      !isEmptyArray(Object.values(isCampaignFilesUpdated).filter((val) => val === true)) ||
      isPostEventDocUpdated
    );
  }, [isCampaignInfoUpdated, isCampaignFilesUpdated, isPostEventDocUpdated]);

  const acmAddLeadSetting = useMemo(() => {
    let uploadSetting = campaignTypeStructureData?.addLeadSetting ?? {
      selfAddEnabled: true,
      needACMApproved: false,
      startDateSetting: {},
      endDateSetting: {
        operator: OperatorEnum.GTE,
        dependsOn: 'startDate',
        value: 100,
      },
      startDate: '',
      endDate: '',
    };
    uploadSetting.startDate = currentCampaign.startDate ?? '';
    switch (uploadSetting.endDateSetting.operator) {
      case OperatorEnum.GTE:
        if (uploadSetting.endDateSetting.dependsOn) {
          uploadSetting.endDate = moment(currentCampaign[uploadSetting?.endDateSetting?.dependsOn ?? ''])
            .add(uploadSetting.endDateSetting.value, 'days')
            .toDate();
        }
        break;
      default:
        break;
    }
    return uploadSetting;
  }, [campaignTypeStructureData?.addLeadSetting, currentCampaign]);

  const isCampaginDraft = useMemo(() => {
    return (
      !currentCampaign.campaignId || [AgencyCampaignTypeStatusEnum.DRAFT + ''].includes(currentCampaign.campaignStatus)
    );
  }, [currentCampaign.campaignId, currentCampaign.campaignStatus]);

  const isCampaignCancel = useMemo(() => {
    return [AgencyCampaignTypeStatusEnum.CANCELED + ''].includes(currentCampaign.campaignStatus);
  }, [currentCampaign.campaignStatus]);

  const isInSchedule = useMemo(() => {
    const currentDate = moment(new Date());
    return (
      moment(acmAddLeadSetting?.startDate).isBefore(moment(currentDate)) &&
      // PACS customizations
      // moment(currentCampaign?.startDate).isBefore(moment(currentDate)) &&
      // moment(currentCampaign?.endDate).isAfter(moment(currentDate)) &&
      moment(acmAddLeadSetting?.endDate).isAfter(moment(currentDate))
    );
  }, [acmAddLeadSetting?.endDate, acmAddLeadSetting?.startDate, currentCampaign?.startDate, currentCampaign?.endDate]);
  const isACMApproved = useMemo(() => {
    return (
      !acmAddLeadSetting?.needACMApproved ||
      [
        AgencyCampaignTypeStatusEnum.ACTIVE + '',
        AgencyCampaignTypeStatusEnum.COMPLETED + '',
        AgencyCampaignTypeStatusEnum.APPROVED + '',
      ].includes(currentCampaign.campaignStatus)
    );
  }, [acmAddLeadSetting?.needACMApproved, currentCampaign.campaignStatus]);

  const addLeadDisable = useMemo(() => {
    if (!isInSchedule) return true;
    return (
      disableUserActions ||
      (acmAddLeadSetting.needACMApproved && (isCampaginDraft || isCampaignCancel || !isACMApproved))
    );
  }, [
    isCampaginDraft,
    isCampaignCancel,
    isInSchedule,
    isACMApproved,
    acmAddLeadSetting.needACMApproved,
    disableUserActions,
  ]);
  const onSaveCampaign = async (options: { quite?: boolean } = {}): Promise<any> => {
    // todo: remove currentStep
    const { quite } = options ?? {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { campaignTypeName, ...formStateData } = formState;
    const details = {
      ...formStateData,
      typeId: campaignTypeStructureData?._id || '',
      typeCode: campaignTypeStructureData?.code || '',
      ...(!formState.participant && { participant: { list: [] } }),
    };
    const { isInfoValid } = checkStepHaveError();

    if (!currentCampaign.campaignId) {
      if (!isInfoValid) {
        return;
      }
      const saveResult = await createNewCampaign(details, dispatch);
      if (saveResult.campaignId) {
        window.history.replaceState(null, '', `/campaign/agencyCampaign/campaign/detail/${saveResult.campaignId}`);
        setCurrentCampaign({ ...currentCampaign, campaignId: saveResult.campaignId, campaignObjId: saveResult._id });
      }
      await reloadData();
    } else {
      await updateCampaign(details, currentCampaign.campaignId, dispatch);
      setPreviousFormState(cloneDeep(formState));
    }
    if (!quite) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign saved successfully`,
          },
        ]),
      );
    }
    return true;
  };

  const sendInvitationToParticipant = (agentList?: string[]) => {
    const agentCodeList =
      agentList ||
      participantSelected.map(({ agentCode }: any) => {
        return agentCode;
      });
    if (currentCampaign.campaignObjId) {
      const data = {
        id: currentCampaign.campaignObjId,
        agentList: agentCodeList,
      };

      sendInvitation(data, dispatch)
        .then(() => {
          setInviteSuccessDialogOpen(true);
        })
        .catch((err) => {
          console.log('err===', err);
        });
    }
  };

  const submitCampaignRequest = async (
    details: any,
    campaignId: string,
    campaignObjId: string,
    importList: any,
    validateParticipantRules: Function,
  ): Promise<any> => {
    const participantSectionTypeStructureData: any = campaignTypeStructureData?.sections.filter(
      (item) => item.key == 'participant',
    )[0];
    const participantNameRules =
      participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName').length > 0
        ? participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName')[0]
            .rules
        : [];
    const eligibilityRules: string[] =
      participantNameRules.filter((item: any) => item.key == 'eligibility').length > 0
        ? participantNameRules.filter((item: any) => item.key == 'eligibility')[0].value
        : [];
    const needEligibilityCheck = (eligibilityRules as string[]).length > 0;
    if (needEligibilityCheck && importList) {
      const { successList, failList } = await checkAgentImportFail(
        importList,
        campaignTypeStructureData,
        campaignObjId,
        eligibilityRules,
        dispatch,
        locale,
      );
      if (failList?.length) {
        await ModalService.open({
          title: Translation('agencyCampaign.create.eligibilityCheck'),
          children: renderfailImportList(failList, async () => {
            const agentCodeList = failList.map(({ agentCode }: any) => {
              return agentCode;
            });
            const data = {
              id: currentCampaign.campaignObjId,
              agentList: agentCodeList,
            };
            await removeParticipant(data, dispatch).then(async () => {
              const passValidator = await validateParticipantRules(successList);
              if (!passValidator) {
                return;
              }
              return submitCampaign(details, campaignId, dispatch);
            });
          }),
          maxWidth: 'md',
          fullWidth: false,
          contentContainerStyle: { padding: '0 20px' },
        });
      } else {
        await submitCampaign(details, campaignId, dispatch);
      }
    } else {
      await submitCampaign(details, campaignId, dispatch);
    }
  };

  const onSubmitCampaign = async () => {
    setSubmitInProgress(true);
    const result = await onSaveCampaign({ quite: true });
    if (!result) {
      setSubmitInProgress(false);
      return;
    }
    const submitFail = Translation('agencyCampaign.create.submitFail');
    const startDate = get(formState, `${submitDateShouldGreaterThan.section}.${submitDateShouldGreaterThan.field}`);

    if (startDate) {
      const currentDate = new Date();
      let minStartDate = addBusinessDays(currentDate, submitDateShouldGreaterThan.value);
      if (moment(startDate).diff(minStartDate, 'days') < 0) {
        const msg =
          submitDateShouldGreaterThan.value === 0
            ? Translation('agencyCampaign.create.submit')
            : TranslationWithVariable('agencyCampaign.create.submit.days', submitDateShouldGreaterThan.value);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: submitFail,
              content: msg,
            },
          ]),
        );
        setSubmitInProgress(false);
        return;
      }
    }
    let { hasError, currentErrorState } = validationHandler.onSubmitErrorValidator({
      exclude: {
        postDocuments: true,
      },
    });

    if (hasError && campaignTypeStructureData) {
      checkStepHaveError();
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: submitFail,
            content: Translation('agencyCampaign.create.fillAll'),
          },
        ]),
      );
      setSubmitInProgress(false);
      return;
    }

    //Check amount of participant
    let getParticipantList: any;
    const validateParticipantRules = async (participants?: any[]) => {
      if (participantNameRules) {
        getParticipantList = participants
          ? { docs: participants, totalDocs: participants.length }
          : await fetchParticipantList(
              {
                limit: 10000,
                page: 1,
                search: '',
                id: currentCampaign.campaignObjId,
              },
              [],
              dispatch,
            );
        const { gte: min, lte: max } = participantNameRules;
        if (min) {
          if (getParticipantList.totalDocs < min) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: submitFail,
                  content: `${min} ${Translation('agencyCampaign.create.participantMin')}`,
                },
              ]),
            );
            return;
          }
        }
        if (max) {
          if (getParticipantList.totalDocs > max) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: submitFail,
                  content: `${max} ${Translation('agencyCampaign.create.participantMax')}`,
                },
              ]),
            );
            return;
          }
        }
      }
      return true;
    };
    const passValidator = await validateParticipantRules();
    if (!passValidator) {
      setSubmitInProgress(false);
      return;
    }

    // Check if RSVP respond is needed
    if (RSVPMandatory && currentCampaign.campaignObjId) {
      const isAllRsvpResponsed = await checkAllRsvpResponded(currentCampaign.campaignObjId, dispatch);
      if (!isAllRsvpResponsed.allResponded) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: submitFail,
              content: `Please collect all the RSVP response before submit`,
            },
          ]),
        );
        setSubmitInProgress(false);
        return;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { campaignTypeName, ...formStateData } = formState;
    const details = {
      ...formStateData,
      typeId: campaignTypeStructureData?._id || '',
      typeCode: campaignTypeStructureData?.code || '',
      ...(!formState.participant && { participant: { list: [] } }),
    };
    if (!currentCampaign.campaignId) {
      const saveResult = await createNewCampaign(details, dispatch);
      if (saveResult.campaignId) {
        setCurrentCampaign({ ...currentCampaign, campaignId: saveResult.campaignId });
        await submitCampaignRequest(
          details,
          saveResult.campaignId,
          saveResult._id,
          getParticipantList?.docs,
          validateParticipantRules,
        );
        await reloadData();
      }
    } else {
      await submitCampaignRequest(
        details,
        currentCampaign.campaignId,
        currentCampaign.campaignObjId,
        getParticipantList?.docs,
        validateParticipantRules,
      );
      await reloadData();
    }

    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Submitted successfully`,
        },
      ]),
    );
    setSubmitInProgress(false);
  };

  const onTabChange = (event: React.SyntheticEvent, newVal: TabsConfig) => {
    setSelectedTab(newVal);
  };

  const onSaveCmapaignInfo = async () => {
    const details = {
      typeId: campaignTypeStructureData?._id || '',
      typeCode: campaignTypeStructureData?.code || '',
      campaign: formState.campaign,
      applicant: formState.applicant,
    };
    if (!currentCampaign.campaignId) {
      const saveResult = await createNewCampaign(details, dispatch);
      if (saveResult.campaignId) {
        window.history.replaceState(null, '', `/campaign/agencyCampaign/campaign/detail/${saveResult.campaignId}`);
        setCurrentCampaign({ ...currentCampaign, campaignId: saveResult.campaignId, campaignObjId: saveResult._id });
      }
      await reloadData();
    } else {
      await updateCampaign(details, currentCampaign.campaignId, dispatch);
      setPreviousFormState(cloneDeep({ ...previousFormState, campaign: formState.campaign }));
    }
  };

  const onSaveAttachment = async (fieldKey: string) => {
    const details = {
      applicant: formState.applicant,
      attachments: { ...previousFormState.attachments, [fieldKey]: formState.attachments[fieldKey] },
    };
    await updateCampaign(details, currentCampaign.campaignId, dispatch);
    // await reloadData();
    setPreviousFormState(
      cloneDeep({
        ...previousFormState,
        attachments: { ...previousFormState.attachments, [fieldKey]: formState.attachments[fieldKey] },
      }),
    );
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: Translation('agencyCampaign.alert.addAttachmentSuccessful'),
        },
      ]),
    );
  };

  const onSubmitDocuments = async () => {
    const postDocs = formState.postDocuments;
    postDocs.triggerSubmit = true;
    const details = {
      postDocuments: formState.postDocuments,
      applicant: formState.applicant,
    };

    const { hasError } = validationHandler.onSubmitErrorValidator({
      include: {
        postDocuments: true,
      },
    });
    // no validate
    if (hasError && campaignTypeStructureData) {
      const submitFail = Translation('agencyCampaign.create.submitFail');
      checkStepHaveError();
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: submitFail,
            content: Translation('agencyCampaign.create.fillAll'),
          },
        ]),
      );
      return;
    }
    await updateCampaign(details, currentCampaign.campaignId, dispatch);
    // todo
    // setPreviousFormState(cloneDeep({ ...formState, ...details }));
    await reloadData();
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Event document saved successfully`,
        },
      ]),
    );
    // setFormStateUpdated(false);
  };

  useEffect(() => {
    validationHandler.immediateErrorValidator();

    // Set campaignEndDate be same as campaignStartDate if validation rule applied
    if (campaignEndDateEqualToStartDate && campaignEndDateEqualToStartDate.setEqual) {
      const sectionKey = campaignEndDateEqualToStartDate.sectionKey;
      const fieldKey = campaignEndDateEqualToStartDate.fieldKey;
      const startDayKey = campaignEndDateEqualToStartDate.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndOfStartDay =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).endOf('day').toDate()
          : null;
      if (dayEndOfStartDay && !moment(currentEndDay).isSame(dayEndOfStartDay)) {
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: `${fieldKey}`, value: dayEndOfStartDay },
        });
      }
    }

    if (campaignEndDateGreaterThanTarget?.setGreateThan) {
      const sectionKey = campaignEndDateGreaterThanTarget.sectionKey;
      const fieldKey = campaignEndDateGreaterThanTarget.fieldKey;
      const startDayKey = campaignEndDateGreaterThanTarget.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndDate =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).add(campaignEndDateGreaterThanTarget.targetDays, 'days').toDate()
          : null;
      if (dayEndDate && !moment(currentEndDay).isSame(dayEndDate)) {
        validationHandler.onDismissErrorHandler(`${sectionKey}_${fieldKey}`, dayEndDate);
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: `${fieldKey}`, value: dayEndDate },
        });
      }
    }
    // eslint-disable-next-line
  }, [formState.count]);

  const getTranslations = () => {
    return {
      are_you_sure_to_submit: Translation('are_you_sure_to_submit'),
      please_make_sure_all_the_documents_are_correct: Translation('please_make_sure_all_the_documents_are_correct'),
    };
  };

  useEffect(() => {
    if (currentCampaign && currentCampaign.rsvpStatus === RsvpStatus.Pending) {
      setShowReminder(true);
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (currentCampaign.campaignStatus) {
      if (
        // todo: is it right?
        (currentCampaign.campaignStatus === AgencyCampaignTypeStatusEnum.DRAFT ||
          currentCampaign.campaignStatus === AgencyCampaignTypeStatusEnum.REJECTED ||
          currentCampaign.campaignStatus === AgencyCampaignTypeStatusEnum.CANCELED) &&
        !disabled
      ) {
        setCanSubmmitCampaign(true);
      } else {
        setCanSubmmitCampaign(false);
      }
    }
  }, [currentCampaign.campaignStatus, disabled]);

  return {
    currentCampaign,
    campaignTypeStructureData,
    selectedTab,
    formState,
    validationHandler,
    disabled,
    disabledEdit,
    campaignEndDateEqualToStartDate,
    campaignEndDateGreaterThanTarget,
    toggleLeadUploadWizard,
    setToggleLeadUploadWizard,
    onTabChange,
    backToList,
    formDispatch,
    onSaveCampaign,
    participantNameRules,
    participantSelected,
    setParticipantSelected,
    disableBulkSelect,
    setDisableBulkSelect,
    setRemoveSuccessDialogOpen,
    sendInvitationToParticipant,
    formStateUpdated,
    onSubmitCampaign,
    // onStepChange,
    isProofUpdated,
    isAttachmentUpdated,
    isCampaignInfoUpdated,
    updateRSVPStatus,
    approvalData,
    onSubmitDocuments,
    getTranslations,
    canEventDocSubmit,
    setCanEventDocSubmit,
    canSubmitCampaign,
    submitInProgress,
    showReminder,
    setShowReminder,
    checkStepHaveError,
    onSaveCmapaignInfo,
    onSaveAttachment,
    acmAddLeadSetting,
    addLeadDisable,
    isInSchedule,
    isACMApproved,
    isCampaignFilesUpdated,
    noPostDoc,
    submitDateShouldGreaterThan,
    loadCampaignTypeStructure,
    disableUserActions,
  };
};
