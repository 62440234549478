import React, { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { FormControl, Box, Select, Grid, InputLabel } from '@mui/material';
import { useIntl } from 'react-intl';
import { Page } from 'src/app/common/components/page/page.component';
import { useReportDetail } from './reports.hook';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100vh',
  },
}));



export const ReportsPage: FC = () => {
  const { classes } = useStyles();
  const intl = useIntl();
  const { t } = useTranslation();
  const { reportListResponse, selectedReport, powerBIEmbedData, handleSelectReport } = useReportDetail();

  return (
    <Page>
      <Box style={{ marginBottom: 10, marginTop: 10 }}>
        <Grid>
          <FormControl required fullWidth color="secondary">
            <InputLabel>{t('powerbi.dashboard.list.report')}</InputLabel>
            <Select label={t('powerbi.dashboard.list.report')} onChange={handleSelectReport} value={selectedReport?.id}>
              {reportListResponse?.map((report, index) => {
                return (
                  <option key={index} value={report.id}>
                    {report.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Box>
      <div id="embedContainer">
        <PowerBIEmbed
          key={powerBIEmbedData.embedToken}
          embedConfig={{
            type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
            id: powerBIEmbedData.reportId,
            embedUrl: powerBIEmbedData.embedUrl,
            accessToken: powerBIEmbedData.embedToken,
            tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
            settings: {
              customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
              },
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              [
                'loaded',
                function () {
                  console.log('Report loaded');
                },
              ],
              [
                'rendered',
                function (data) {
                  console.log('Report rendered');
                  console.log('data', data);
                },
              ],
              [
                'error',
                function (event) {
                  console.log(event?.detail);
                },
              ],
              ['visualClicked', (data) => console.log('visual clicked', data)],
              ['pageChanged', (event) => console.log(event)],
            ])
          }
          cssClassName={classes.root}
          getEmbeddedComponent={async (embeddedReport) => {
            (window as any).report = embeddedReport;
          }}
        />
      </div>
    </Page>
  );
};
