import { Dispatch } from 'react';
import { AGENT_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { globalStore } from 'src/app/common/helpers/global-store.util';
import { AgentStatusEnum, CampaignTypeEnum } from '../types/campaign-types';
import { GlobalHelper } from 'src/app/common/helpers/GlobalHelpers';
import { Translation } from 'src/app/i18n';

export const fetchCampaignUnreadCount = async (query?: any, dispatch?: Dispatch<any>) => {
  const params = { agentCode: globalStore.getAgentCode() };
  const path = `${AGENT_URL}/agents/me/campaigns/all-unread-campaign-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMarketingCampaignList = async (query?: any, dispatch?: Dispatch<any>) => {
  const params: any = {
    ...query,
    limit: 1000,
    page: 1,
    type: CampaignTypeEnum.normal,
  };
  const path = `${AGENT_URL}/agents/me/campaigns/onboard-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchServicingCampaignList = async (query?: any, dispatch?: Dispatch<any>) => {
  const params: any = {
    ...query,
    limit: 1000,
    page: 1,
  };
  const path = `${AGENT_URL}/agents/me/campaigns/servicing-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMassCampaignList = async (query?: any, dispatch?: Dispatch<any>) => {
  const params: any = {
    ...query,
    limit: 1000,
    page: 1,
    type: CampaignTypeEnum.mass,
  };
  const path = `${AGENT_URL}/agents/me/campaigns/onboard-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignDetail = async (
  id: string,
  dispatch?: Dispatch<any>,
  query?: { page?: number; limit?: number },
) => {
  let params = {
    page: 1,
    limit: 100,
    sortBy: 'leadStatus',
  };
  if (query) {
    params = { ...params, ...query };
  }
  const path = `${AGENT_URL}/agents/me/campaigns/${id}`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const campaignAcceptLeads = async (ids: string[], dispatch?: Dispatch<any>) => {
  const requestBody = { ids: ids, agentStatus: AgentStatusEnum.accepted, rejectedReason: '' };
  const path = `${AGENT_URL}/agents/me/leads/agent-status`;
  return apiClient
    .patch<any>(path, requestBody)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchActivityStatusFeedback = async (campaignId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me/campaigns/feedbacks/${campaignId}`;

  return apiClient
    .get<any>(path)
    .then((response) => {
      const result =response.data;
      result.push({
        key: null,
        value: Translation('campaign.servicing.feedback.pendingStatus')
    })
      return result;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const patchLeadDetail = async (id: string, body: any, dispatch?: Dispatch<any>): Promise<any> => {
  let path = `${AGENT_URL}/agents/me/leads/${id}/update`;
  return apiClient
    .patch<any>(path, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getUnreadCampaign = async () => {
  const agentCode = globalStore.getAgentCode();
  const path = `${AGENT_URL}/agents/me/campaigns/all-unread-campaign-count`;
  return apiClient
    .get<any>(path, { params: { agentCode, groupByType: true } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err);
    });
};

export const patchOpportunityById = async (
  id: string,
  body: { agentStatus: AgentStatusEnum; rejectedReason: string | null },
  dispatch?: Dispatch<any>,
) => {
  const path = `${AGENT_URL}/agents/me/leads/${id}/agent-status`;
  return apiClient
    .patch<any>(path, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};
